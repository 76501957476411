import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";

import "./components/header/header";

import "./buurtbemiddeling_enschede.scss";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
